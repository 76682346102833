// Here you can add other styles

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.small-bottom-margin {
  margin-bottom: 0.5rem !important;
}

.no-bottom-border {
  border-bottom: none !important;
}

.card-header-no-bg-no-border {
  background-color: white;
  border-bottom: 0;
}

.text-white {
  color: white !important;
}

.text-value-overview {
  font-size: 1.15rem;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}
.loading-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(74, 72, 72, 0.5);
  color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-text {
  cursor: text;
}

.sidebar {
  background: #1e537b;
}

.sidebar .nav-link.active {
  background: #266698;
}

.sidebar .nav-link .nav-icon {
  color: #e8e8e8;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}

.app-header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%231e537b' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

tr.align-middle > td {
  vertical-align: middle;
}

tr.disabled-row > td {
  text-decoration: line-through;
  color: grey;
}

.login-box img {
  max-height: 150px;
}

.login-box {
  text-align: center;
}

/* .loading {
  font-size: 30px; 
} */

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

.loading.loading-fixedsize {
  width: 50px;
  display: inline-block;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.navbar-brand img {
  max-width: 150px;
}

.navbar-brand-full {
  max-height: 40px;
}

.navbar-brand-minimized {
  max-height: 45px;
}

.input-group-prepend .input-group-text {
  min-width: 100px;
}

.min-width-auto {
  min-width: auto !important;
}

.DailyChart .btn.btn-outline-primary:not(.active):hover {
  background-color: #f8f9fa !important;
  color: #20a8d8 !important;
}
.DailyChart .btn.btn-outline-primary.active:hover {
  background-color: #219dca !important;
  color: white !important;
}

.single-consumable-view .input-group-prepend .input-group-text {
  min-width: 130px;
}

.single-device-view .input-group-prepend .input-group-text {
  min-width: 151px;
}

.login-view .input-group-prepend .input-group-text,
.register-view .input-group-prepend .input-group-text,
.verify-view .input-group-prepend .input-group-text {
  min-width: auto;
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  width: 100%;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.mb-0 > .breadcrumb {
  margin-bottom: 0;
}

.table-responsive {
  min-height: 140px;
}

.matrix-chart-tooltip {
  max-width: none;
}

.kpi-widget .card-body .kpi-widget-content {
  font-size: 1.5em;
}

.selector-breadcrumb input {
  overflow: hidden;
}
.cycles-expansion {
  .row-expansion-style {
    padding-left: 28px;
    padding-right: 0;
  }
}

.inline-block {
  display: inline;
  width: auto;
}

.border-right {
  border-right: 1px solid black;
}

.border-left {
  border-left: 1px solid black;
}

.border-top {
  border-top: 1px solid black;
}
.border-bottom {
  border-bottom: 1px solid black;
}

.editable-cell {
  min-width: 185px;
  height: 46px;
}

.h-10 {
  height: 10% !important;
}
.h-20 {
  height: 20% !important;
}
.h-33 {
  height: 33.33% !important;
}
.h-40 {
  height: 40% !important;
}
.h-60 {
  height: 60% !important;
}
.h-70 {
  height: 70% !important;
}
.h-80 {
  height: 80% !important;
}

.flex-align-content-middle {
  display: flex;
  align-items: center;
}
.flex-align-content-right {
  display: flex;
  justify-content: flex-end;
}
.flex-align-content-center {
  display: flex;
  justify-content: center;
}

.awareness-number {
  font-size: 4vmin;
  margin-right: 1vw;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 5vmin;
  }
}
.awareness-unit {
  font-size: 4vmin;
}

.awareness-title {
  font-size: 6vmin;
  margin-top: 8px;
  .awareness-title-small-font {
    font-size: 4vmin;
  }

  @media (min-width: 576px) {
    font-size: 4vmin;

    .awareness-title-small-font {
      font-size: 3vmin;
    }
  }

  @media (min-width: 1200px) {
    font-size: 6vmin;

    .awareness-title-small-font {
      font-size: 4vmin;
    }
  }
}
.awareness-main-title {
  display: flex;
  font-size: 6vmin;
  font-weight: bold;
  text-align: center;
}
.awareness-main-title-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.awareness-legend {
  font-size: 4vmin;
  font-weight: lighter;
}

.awareness-title-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: center;
  }
}

.awareness-kpi {
  display: flex;
  justify-content: flex-start;
}

.awareness-kpi-img {
  display: flex;
  justify-content: flex-start;
  width: auto;
  align-items: center;
  text-align: right;

  @media (min-width: 768px) {
    justify-content: flex-end;
    width: 100%;
  }

  img {
    width: 10vmin !important;
    height: 10vmin;
  }
}

.awareness-kpi-text {
  display: flex;
  align-items: center;
  width: auto;

  @media (min-width: 768px) {
    width: 100%;
  }
}

.awareness-screen-logos {
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  align-content: center;
}

.awareness-screen-logos img {
  margin-left: 5%;
  margin-right: 5%;
  max-height: 15vmin;
  max-width: 15vmin;
  object-fit: contain;
}

.screen-list-date-picker {
  display: inline-flex;
  width: auto;
}
.screen-list-date-picker .form-control {
  width: 150px;
}
.screen-list-date-picker .input-group-text {
  min-width: auto;
}

.hygiene-number {
  font-size: 5vh;
  margin-right: 1vw;
  font-weight: bold;
}
.hygiene-unit {
  font-size: 4vh;
}

.hygiene-title {
  font-size: 6vh;
  text-align: center;
}

.hygiene-legend {
  font-size: 4vh;
  font-weight: lighter;
}
.hygiene-title-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;
}

.hygiene-kpi-img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.hygiene-kpi-text {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

.hygiene-kpi-title {
  font-size: 5vh;
  margin-bottom: 5%;
}

.hygiene-kpi-title img {
  height: 7vh;
}

.hygiene-kpi-comparison {
  width: 90%;
  font-size: 2.5vh;
}

.hygiene-kpi-comparison-left {
  text-align: left;
}

.hygiene-kpi-comparison-right {
  text-align: right;
}

.hygiene-screen-logos img {
  margin-left: 5%;
  margin-right: 5%;
}

.water-dispensers table {
  position: relative;
  overflow: hidden;
  margin-top: 54px; /* for the heading columns */
}

.water-dispensers thead {
  display: block;
  position: fixed;
  overflow: hidden;
  margin-top: -54px; /* to use the space reserved for it */
  background-color: white; /* to make the headers background opaque */
}

.water-dispensers .card {
  height: calc(
    100vh - 155px
  ); /* to use the full available space, since now the table is absolutely positioned it can't infer the content size */
}

.water-dispensers th,
.water-dispensers td {
  min-width: 185px; /* to fix the width that best corresponds to the editable cells when editing*/
}

.water-dispensers-table {
  height: calc(100vh - 286px);
  width: calc(100% - 40px);
  overflow: auto;
  position: absolute; /* to be sure it's below the name table */
}

.water-dispensers-name {
  height: calc(100vh - 302px);
  background-color: white; /* to make the fixed columns opaque */
  position: absolute; /* to fix the columns above the table */
  overflow: auto;
  -ms-overflow-style: none; /* to hide the scrollbar in Internet Explorer 10+ */
  scrollbar-width: none; /* to hide the scrollbar in Firefox */
}

.water-dispensers-name::-webkit-scrollbar {
  display: none; /* to hide the scrollbar in Safari and Chrome */
}

.water-dispensers-name td {
  height: 46px; /* to have the same height as editable-cells */
}

.water-dispensers-name th,
.water-dispensers-table th {
  height: 53px; /* fixing the heading column size for both tables */
}

.app-body.no-header {
  margin-top: 0;
}

@media (max-width: 576px) {
  button.full-width {
    width: 100%;
  }
}

.overview-wrapper {
  padding: 30px 0;
  background-color: white;
  height: 100%;
}

.overview-row:nth-child(2n-1) {
  background-color: #eeeeee;
}

.overview-row {
  padding: 0 0 30px 0;
  text-align: center;
  justify-content: center;
}
.overview-row-widgets {
  justify-content: center;
}

.overview-row > .col {
  max-width: 940px;
}

.overview-row .spinner-border {
  margin: 3rem;
}

.overview-widget-icon img {
  width: 6rem;
  max-width: 6rem;
  margin: 0 1rem 1rem 1rem;
}

.overview-widget-icon i {
  font-size: 5rem;
  color: #1e537b;
  margin: 1rem;
}

.overview-widget-icon i.fa-shipping-timed {
  font-size: 4rem;
  margin-top: 2rem;
}

.overview-row-title {
  margin-top: -20px;
  margin-bottom: 10px;
  font-size: 24pt;
  font-weight: bold;
  text-transform: uppercase;
  color: #1e537b;
  text-align: center;
}

.overview-widget-text {
  font-size: 16pt;
  margin-bottom: -5px;
}

.overview-widget-kpi-small {
  font-weight: bold;
}

.kpi-green {
  color: #38761d;
}
.kpi-red {
  color: #990000;
}

.overview-widget-parenthesis {
  font-size: 13pt;
  margin-bottom: 0;
}

.overview-widget-kpi-period {
  text-transform: uppercase;
  font-size: 18pt;
  font-weight: bold;
  color: #0b5394;
  margin-top: 1rem;
}

p.overview-widget-kpi-number {
  font-size: 28px;
  margin-bottom: 0;
  font-weight: bold;
}

.overview-widget-kpi {
  margin-top: 1rem;
}

p.overview-widget-doughnut-kpi {
  margin-bottom: -5px;
}

.overview-widget-doughnut {
  margin-bottom: 1rem;
}

.overview-water-consumption .overview-widget-doughnut.row {
  padding-top: 1rem;
}

.overview-widget-doughnut-wrapper {
  max-width: 200px;
  margin: auto;
  min-height: 6rem;
}

.badge {
  position: relative;
  background: rgb(255, 0, 0);
  color: white;
  margin-left: 60%;
  margin-top: -60%;
}
.circle {
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #eeeeee;
  display: inline-block;
  box-sizing: border-box;

  span {
    color: gray;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: inherit;
    font-weight: inherit;
  }
}

.notification-text {
  margin-bottom: 0;
}

.notification-date {
  margin-bottom: 0;
  color: grey;
  font-size: x-small;
}

.setup-wizard h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.setup-wizard-sensor {
  display: flex;
  align-items: center;
  justify-content: center;
}

.setup-wizard-sensor img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.setup-wizard-sensor-selected {
  border: 3px solid #20a8d8;
}

.setup-wizard-small-btn {
  border: 1px solid #20a8d8;
  border-radius: 5px;
  color: #20a8d8;
  background-color: white;
}

.setup-wizard-form-group {
  margin-bottom: unset !important;
}

.setup-wizard-sensor img:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
}

.setup-wizard-error {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;

  background-color: #c50000;
  color: white;

  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.setup-wizard-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;

  background-color: #007dc5;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  svg {
    height: 282px;
    z-index: 10001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -55%);
    #rect4487 {
      fill: #007dc5;
    }
  }
  .loader {
    height: 200px;
    width: 174px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
  }
  .water {
    background: white;
    position: relative;
    top: 50%;
    height: 200%;
    width: 200%;
    z-index: 1000;
    border-radius: 38%;
    left: -50%;
    transform: rotate(360deg);
    transition: all 1s ease;
    animation: wave 5s linear infinite, movetop 10s linear forwards;
  }
  @keyframes movetop {
    0% {
      top: 60%;
    }
    100% {
      top: 10px;
    }
  }
  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.setup-wizard {
  margin: auto;
  max-height: 800px;
  max-width: 500px;
}

.wizard-description {
  text-align: justify;
  font-style: italic;
}

.wizard-recap-table th {
  padding: 0 15px;
}

.app-header .dropdown-item.notification-dropdown-item {
  min-width: 20vw;
  cursor: "default";
  white-space: normal;
}

.imageCropperPreviewContainer {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  justify-content: flex-end;
  width: unset;
}

.imageCropperPreviewImage {
  width: 270px;
  height: 200px;
  object-fit: contain;
}

.imageCropperPreviewButton {
  margin: 4px 0 2px 2px;
  width: 133px;
}

.imageCropperButton {
  width: 100%;
}
.videoInsert {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.qr-code-scanner {
  height: 60vh;
}

.table-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 800px;
  width: 100%;
}
