// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~@fortawesome/fontawesome-pro/css/all.min.css";
// Import Main styles for this application
@import "./scss/style.scss";

// Fixes ugly lines in tables
.table-striped tbody tr:nth-of-type(odd) {
  background-color: unset !important;
}
