@font-face {
  font-family: "Museo-700";
  src: local("Museo-700"), url(./fonts/Museo-700.otf) format("opentype");
}
@font-face {
  font-family: "Gravur Condensed Regular";
  src: local("Gravur Condensed Regular"),
    url(./fonts/GravurCondensed-Regular.otf) format("opentype");
}

.awareness-screen-wfw {
  &.with-main-title {
    .awareness-title-wrapper {
      margin-top: 0;

      .awareness-title {
        font-size: 4vh;
      }
    }
    .awareness-number {
      font-size: 6vh;
    }
    .awareness-unit {
      font-size: 3vh;
    }
  }

  .awareness-main-title {
    font-family: "Museo-700", "Times New Roman", Times, serif;
  }

  .awareness-title-wrapper {
    display: block;
    color: #20b8cf;
    padding: 0;
    margin-bottom: -2vh;
    font-family: "Museo-700", "Times New Roman", Times, serif;
    margin-top: 2vh;

    .awareness-title {
      font-size: 5vh;
      margin-top: 0;
    }
  }
  .awareness-legend {
    color: #20b8cf;
    font-size: 15pt;
    font-family: "Gravur Condensed Regular", "Trebuchet MS",
      "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    display: flex;
    text-align: right;
    height: 5%;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
    padding: 0 30px;
  }
  .awareness-kpi {
    background-color: #20b8cf;
    color: white;
    border-radius: 25px;
  }
  .awareness-kpi-img {
    justify-content: center;
  }
  .awareness-kpi-img img {
    max-height: 75%;
    max-width: 50%;
    width: auto !important;
    height: auto !important;
    position: absolute;
  }
  .awareness-kpi-text {
    align-items: normal;
    flex-direction: column;
    justify-content: center;
  }
  .awareness-number {
    font-size: 7vh;
    font-family: "Museo-700", "Times New Roman", Times, serif;
    line-height: 1;
  }
  .awareness-unit {
    font-family: "Gravur Condensed Regular", "Trebuchet MS",
      "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  }
  .left-col .row {
    margin: 0px 0px 20px 30px;
  }
  .right-col .row {
    margin: 0px 30px 20px 0px;
  }
  .awareness-screen-logos img {
    margin-left: 45px;
    margin-right: 45px;
  }
  .awareness-screen-logos {
    display: block;
  }
}
